@use '@angular/material' as mat;
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
/*#region Material*/
$md-sarpprimary: (
    50 : #e0ecf5,
    100 : #b3cfe5,
    200 : #80afd4,
    300 : #4d8ec2,
    400 : #2676b5,
    500 : #005ea8,
    600 : #0056a0,
    700 : #004c97,
    800 : #00428d,
    900 : #00317d,
    A100 : #aac4ff,
    A200 : #77a1ff,
    A400 : #447eff,
    A700 : #2a6cff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-sarpsecondary: (
    50 : #fef3e0,
    100 : #fbe0b3,
    200 : #f9cc80,
    300 : #f7b74d,
    400 : #f5a726,
    500 : #f39800,
    600 : #f19000,
    700 : #ef8500,
    800 : #ed7b00,
    900 : #ea6a00,
    A100 : #ffffff,
    A200 : #ffebde,
    A400 : #ffccab,
    A700 : #ffbc91,
    contrast: (
        50 : #fff,
        100 : #fff,
        200 : #fff,
        300 : #fff,
        400 : #fff,
        500 : #fff,
        600 : #fff,
        700 : #fff,
        800 : #fff,
        900 : #fff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-sarp: (
    50 : #fff9e0,
    100 : #fff0b3,
    200 : #ffe680,
    300 : #ffdb4d,
    400 : #ffd426,
    500 : #ffcc00,
    600 : #ffc700,
    700 : #ffc000,
    800 : #ffb900,
    900 : #ffad00,
    A100 : #ffffff,
    A200 : #fffaf2,
    A400 : #ffe8bf,
    A700 : #ffdfa6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #fff,
        600 : #fff,
        700 : #fff,
        800 : #fff,
        900 : #fff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-sarpwarn: (
    50 : #f6e3e4,
    100 : #e9b9bc,
    200 : #da8b90,
    300 : #cb5c64,
    400 : #c03942,
    500 : #b51621,
    600 : #ae131d,
    700 : #a51018,
    800 : #9d0c14,
    900 : #8d060b,
    A100 : #ffbabc,
    A200 : #ff878a,
    A400 : #ff5458,
    A700 : #ff3b3f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);
/*#endregion */
/* changement de typo : cf : https://v15.material.angular.io/guide/typography
typography: mat.define-typography-config(
          $body-1: mat.define-typography-level(16px, 24px, 500, $letter-spacing: -0.05em),
          $body-2: mat.define-typography-level(14px, 20px, 250, $letter-spacing: -0.025em)
        ),
*/
$light-theme-primary: mat.define-palette($md-sarpprimary);
$light-theme-accent: mat.define-palette($md-sarpsecondary);
$light-theme-warn: mat.define-palette($md-sarpwarn, 800, 800);
$light-theme: mat-light-theme((
        color: (
          primary: $light-theme-primary,
          accent: $light-theme-accent,
          warn: $light-theme-warn
        ),
        typography: mat.define-typography-config(),
        density: 0,
       ));
$dark-theme-primary: mat.define-palette($md-sarpprimary, 400, 400);
$dark-theme-accent: mat.define-palette($md-sarpsecondary, 800, 800);
$dark-theme-warn: mat.define-palette($md-sarpwarn, 300, 300);
$dark-theme: mat-dark-theme((
        color: (
          primary: $dark-theme-primary,
          accent: $dark-theme-accent,
          warn: $dark-theme-warn
        ),
        typography: mat.define-typography-config(),
        density: 0,
       ));
$primary: #005ea8;
$success: rgb(51, 182, 121);
$accent: #f39800;
$warn: #df1e2b;
$danger: #df1e2b;
$activated: #dddd;
$activable: #dddd;
$not-activated: #ffffff;
$dark-theme-background: #303030;
$light-theme-background:#f1f1f1;
// Custom array
$dark-theme-mat-row-background-color: rgba(255,255,255,0.3);
$light-theme-mat-row-background-color: rgba(0,0,0,0.3);
$dark-theme-mat-header-cell-border-color: #6a6869;
$dark-theme-mat-cell-border-color: #6a6869;
$dark-theme-mat-header-row-background-color: #4e4e4e;
$dark-theme-mat-row-background-color-even: #4e4e4e;
$dark-theme-mat-row-background-color-odd: #3b3b3b;
$light-theme-mat-header-cell-border-color: #dadada;
$light-theme-mat-cell-background-color: #dadada;
$light-theme-mat-header-row-background-color: #f9f9f9;
$light-theme-mat-row-background-color-odd: #eaecee;
$light-theme-mat-row-background-color-even: #ffffff;



caption{
  padding: 0px;
}

.dark-theme {
  @include mat.all-legacy-component-colors($dark-theme);
  --fc-list-event-hover-bg-color: #d8d8d88a;
}

.dark-theme .mat-icon.mat-primary {
  background: white;
  border-radius: 3px;
}

.dark-theme input:-webkit-autofill,
.dark-theme input:-webkit-autofill:hover, 
.dark-theme input:-webkit-autofill:focus, 
.dark-theme input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}

.dark-theme .mat-icon.mat-success {
  background: white;
  border-radius: 3px;
}
.mat-icon.mat-success {
  color: $success;
}

.dark-theme .text-complete {
  color: #cacaca;
}

body.dark-theme .navbar-inverse .navbar-nav>.active>a, 
.navbar-inverse .navbar-nav>.active>a:focus, 
.navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  background-color: $primary;
  border-radius: 4px;
}

::ng-deep .mat-tab-label-active.mat-tab-label {
  opacity: 1;
}

body.light-theme{
  background-color: $light-theme-background;
}

.light-theme .mat-drawer-container {
  background-color: $light-theme-background !important;
}
.light-theme .mat-drawer-inner-container,
.light-theme .mat-tab-labels,
.light-theme .fc-view-harness {
  background-color: white !important;
}
.light-theme input:-webkit-autofill,
.light-theme input:-webkit-autofill:hover, 
.light-theme input:-webkit-autofill:focus, 
.light-theme input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #000 !important;
}
body.dark-theme{
  background-color: $dark-theme-background;
  border-color: #fcfeff;
  color: #F6F4F1;
}

a {
  color: #000;
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
}

.dark-theme a {
  color: #fff;
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
}

.light-theme {
  @include mat.all-legacy-component-themes($light-theme);
}

.light-theme .mat-drawer {
  background-color: transparent;
  border-right: none;
}

.light-theme .text-complete {
  color: #808080;
}

/*#region fullCalendar*/

.dark-theme .fc-list-day-cushion {
  background-color: $dark-theme-background!important;
}

.dark-theme .fc-unthemed .fc-list-item:hover td{
  background-color: $activable!important;
}

.dark-theme .fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid rgba(221, 221, 221, 0.164);
  border: 1px solid var(--fc-border-color,rgba(221, 221, 221, 0.164));
}

.dark-theme .fc-unthemed td.fc-today {
    background-color: $not-activated!important;
    border-color: white;
}

.dark-theme .fc-unthemed .fc-list-heading td {
  background-color: $not-activated;
}

.fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid rgba(221, 221, 221, 0.164);
  border: 1px solid var(--fc-border-color,rgba(221, 221, 221, 0.164));
}

.fc .fc-button:disabled .fc-today-button {
  background-color: $activated;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--fc-today-bg-color,rgba(255,220,40,.15));
}

.fc-scroller {
  scroll-behavior: auto;
}

.fc-event {
  border-color: $not-activated!important;
}

.fc .fc-button-primary {
  background-color: $not-activated!important;
  color: #000!important;
}

.fc-button-primary:disabled {
  background-color: $activated!important;
}

.fc-button:hover {
  background-color: $activable!important;
  @include mat.elevation(8);
  &:hover {
    @include mat.elevation(10);
  }
}

.fc-button-primary:not(:disabled):active,
.fc-today-button,
.fc-button-primary:not(:disabled).fc-button-active {
  color: #000!important;
  background-color: $activated!important;
}

::ng-deep .fc-event{
  cursor: pointer;
}
/*#endregion */


/*#region materialButtons*/

.mat-stroked-button {
  padding-left: 10px!important;
  max-height: 34px!important;
  padding-right: 10px!important;
}

.mat-raised-button {
  padding-left: 10px!important;
  max-height: 34px!important;
  padding-right: 10px!important;
}

.mat-raised-button.mat-success,
.mat-flat-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success {
  @extend %standardButton;
  color: #fff;
  background-color: $success;
}


.mat-raised-button.mat-danger,
.mat-flat-button.mat-danger,
.mat-stroked-button.mat-danger,
.mat-fab.mat-danger,
.mat-mini-fab.mat-danger {
  @extend %standardButton;
  color: #fff;
  background-color: #b51621;
  border-color: #b51621;
}

.mat-raised-button.mat-warn,
.mat-flat-button.mat-warn,
.mat-stroked-button.mat-warn,
.mat-fab.mat-warn,
.btn-warn,
.mat-mini-fab.mat-warn {
  @extend %standardButton;
  color: #fff;
  background-color: #f39800;
  border-color: #f39800;
}

.mat-raised-button.mat-not-activated,
.mat-flat-button.mat-not-activated,
.mat-stroked-button.mat-not-activated,
.mat-fab.mat-not-activated,
.mat-mini-fab.mat-not-activated {
  @extend %not-activated;
}

.mat-raised-button.mat-activated,
.mat-flat-button.mat-activated,
.mat-fab.mat-activated,
.mat-stroked-button.mat-activated,
.mat-mini-fab.mat-activated {
  @extend %activated;
}

.mat-raised-button.mat-info,
.mat-flat-button.mat-info,
.mat-stroked-button.mat-info,
.mat-fab.mat-info,
.mat-mini-fab.mat-info {
  @extend %standardButton;
  color: #000;
  background-color: #ffe680;
  border-color: #f39800;
}

.mat-raised-button.mat-info-blue,
.mat-flat-button.mat-info-blue,
.mat-fab.mat-info-blue,
.mat-stroked-button.mat-info-blue,
.mat-mini-fab.mat-info-blue,
.chip.info-blue {
  @extend %standardButton;
  color: #fff;
  background-color: #039be5;
  border-color: #039be5;
}

.mat-raised-button.mat-info-green,
.mat-flat-button.mat-info-green,
.mat-fab.mat-info-green,
.mat-stroked-button.mat-info-green,
.mat-mini-fab.mat-info-green,
.chip.info-green {
  @extend %standardButton;
  color: #fff;
  background-color: #33b679;
  border-color: #33b679;
}

.mat-raised-button.mat-info-dark-blue,
.mat-flat-button.mat-info-dark-blue,
.mat-stroked-button.mat-dark-blue,
.mat-fab.mat-info-dark-blue,
.mat-mini-fab.mat-info-dark-blue,
.chip.info-dark-blue {
  @extend %standardButton;
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.mat-raised-button.mat-info-red,
.mat-flat-button.mat-info-red,
.mat-fab.mat-info-red,
.mat-mini-fab.mat-info-red,
.chip.info-red {
  @extend %standardButton;
  color: white;
  background-color: #d50000;
  border-color: #d50000;
}


.mat-raised-button.mat-info-beige,
.mat-flat-button.mat-info-beige,
.mat-fab.mat-info-beige,
.mat-stroked-button.mat-info-beige,
.mat-mini-fab.mat-info-beige,
.chip.info-beige {
  @extend %standardButton;
  color: black;
  background-color: #EEF3D1;
  border-color: #EEF3D1;
}

.mat-raised-button.mat-info-light-yellow,
.mat-flat-button.mat-info-light-yellow,
.mat-fab.mat-info-light-yellow,
.mat-mini-fab.mat-info-light-yellow,
.chip.info-yellow {
  @extend %standardButton;
  color: black;
  background-color: #FAE7BA;
  border-color: #FAE7BA;
}

.mat-raised-button.mat-info-light-brown,
.mat-flat-button.mat-info-light-brown,
.mat-fab.mat-info-light-brown,
.mat-mini-fab.mat-info-light-brown,
.chip.info-light-brown {
  @extend %standardButton;
  color: black;
  background-color: #F4BB86;
  border-color: #F4BB86;
}

.mat-stroked-button.mat-info-danger {
  @extend %standardButton;
  color: $warn;
  background-color: white;
  border-color: $warn!important;
}
.mat-stroked-button.mat-info-warn {
  @extend %standardButton;
  color: $accent;
  background-color: white;
  border-color: $accent!important;
}
.mat-stroked-button.mat-success {
  @extend %standardButton;
  color: $primary;
  background-color: white;
  border-color: $primary !important;
}
.mat-stroked-button.mat-info-green {
  @extend %standardButton;
  color: $success;
  background-color: white;
  border-color: $success !important;
}
.mat-stroked-button.mat-info-bg-green {
  @extend %standardButton;
  color: white;
  background-color: $success;
  border-color: white !important;
}


body.dark-theme .mat-raised-button.mat-default,
body.dark-theme .mat-flat-button.mat-default,
body.dark-theme .mat-stroked-button.mat-default,
body.dark-theme .mat-fab.mat-default,
body.dark-theme .mat-mini-fab.mat-default {
  @extend %standardButton;
  border-color: #7e7e7e;
  color: white;
  background-color: #7e7e7e;
}


body.light-theme .mat-raised-button.mat-default,
body.light-theme .mat-flat-button.mat-default,
body.light-theme .mat-stroked-button.mat-default,
body.light-theme .mat-fab.mat-default,
body.light-theme .mat-mini-fab.mat-default {
  @extend %standardButton;
  border-color: #edf3f5;
  color: black;
  background-color: #edf3f5;  
}

/*#endregion*/


/*region table*/

.table>tbody>tr>td {
  padding: 1px!important;
  
}

tr.example-element-row:hover {
  background: #dddd;
  cursor: pointer;
}

.mat-table-sticky {
  border-top: 1px solid #e0e0e0;
}
tr.example-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

/*endregion */
.chip {
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
}


.mat-form-field-required-marker { 
  color: #f37500;
  font-size: 18px;
}

.mat-label {
  font-family: 'Roboto', sans-serif;
}

.form-control[disabled] {
  background-color: rgb(206, 206, 206);
  
}
.navbar-inverse {
  background-color: $primary;
  color: white; 
  //z-index: auto;
}

%standardButton {
  // @include mat.elevation-transition;
  // &:hover {
  //   @include mat.elevation(10);
  // }
  // z-index: 2;
}

%activated {
  color: #000;
  background-color: $activated;
  border-color: $activated;
  &:hover {
    background-color: $activable;
  }
}

%not-activated {
  @extend %standardButton;
  color: #000;
  background-color: $not-activated;
  border-color: $not-activated;
  &:hover {
    background-color: $activable;
  }
}

.dark-theme .data-class {
  background-color:  #9898988a ;
}

.light-theme .data-class {
  background-color:  #eef0f3 ;
}

.dark-theme .text-barrer {
  color: #4e4e4e;
}

.light-theme .text-barrer {
  color: gray;
}