@use '@angular/material' as mat;
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();
@import 'theme.scss';
/*
$light-theme-primary: mat.define-palette(mat.$blue-palette, 900);
$light-theme-accent: mat.define-palette(mat.$orange-palette, A400, A100, A400);
$light-theme-warn: mat.define-palette(mat.$red-palette);
$light-theme: mat.define-light-theme($light-theme-primary, $light-theme-accent, $light-theme-warn);

$dark-theme-primary: mat.define-palette(mat.$blue-palette, 900);
$dark-theme-accent: mat.define-palette(mat.$orange-palette, A400, A100, A400);
$dark-theme-warn: mat.define-palette(mat.$red-palette);
$dark-theme: mat.define-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-warn);

.dark-theme {
  @include mat.all-legacy-component-themes($dark-theme);
}
*/
tr.mat-row {
  height: 10px;
}


.mat-column-statut,
th.col-statut {
  min-width: 85px;
}

.dark-theme .fc-unthemed .fc-list-item:hover td{
  background-color: rgb(30, 40, 100);
}

/*
.accordion-align-center {
  justify-content: space-between;
  align-items: center;
}

.accordion-align-center .mat-expansion-panel-header-title,
.accordion-align-center .mat-expansion-panel-header-description,
.accordion-align-center .mat-icon {
  flex-basis: 0;
}
*/
table {
  width: 100%;
  text-align: start;
  font-size: 13px !important;
}

.mat-table {
  font-size: 13px !important;
}

.table-inner-scroll-container {
  height: calc(100vh - 194px) !important;
  overflow: auto;
  max-width: auto;
}

.table-inner-scroll {
  overflow: auto;
  max-width: auto;
}

td, td *, .mat-cell {
  font-size: 13px !important;
}
td .mat-icon {
  font-size: 17px !important;
  vertical-align: middle!important;
}

.mat-title-icon-expension-header {
  justify-content: space-between;
  flex-grow: 1;
}

.mat-icon-expension-header {
  justify-content: space-between;
  align-items: center;
  flex: auto;
  flex-grow: 1;
  display: flex;
}

h3 {
  margin-top: 10px!important;
}
.mat-panel-description-add-icon {
  justify-content: center;
  text-align: initial;
  flex-grow: 8!important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.fc-unthemed .fc-list-empty {
  background-color: transparent!important;
}
td.selected {
  background-color:  #77a1ff!important; // TODO select Correct color
  color:  white!important; // TODO select Correct color
}

.checkbox-section {
  margin: 12px 0;
}

.checkbox-margin {
  margin: 0 12px;
}

ul {
  list-style-type: none;
  margin-top: 4px;
}

.mat-tooltip {
  font-size: small !important;
}

.full-width {
  width: 100%;
}
.flex-modal {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: flex-start; 
  align-items: flex-start;
}

.close-button{
  float: right;
  top:-24px;
  right:-24px;
  cursor: pointer
  
}

.tabResponsives {
  display: flex;
  flex-direction: column;
}

.mat-tablee {
  overflow: auto;
}

.row {
  margin: 10px;
}

label {
  margin-right: 5px;
}

.align-right {
  display: flex; 
  justify-content: flex-end; 
  align-items: flex-end;
}

.align-center {
  display: flex; 
  justify-content: center; 
  align-items: center;
}


.align-left {
  display: flex; 
  justify-content: flex-start; 
  align-items: flex-start;
}

html, body {
  font-family: 'Roboto', sans-serif;
}


body {
  margin: 0; 
}


h1, h2, h3, h4, h5 {
  font-family: 'Sansa Pro', sans-serif; 
}

body * {
  scrollbar-width: thin;
  scrollbar-color: darkgray transparent;
}

/* Works on Chrome, Edge, and Safari */
body *::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

body *::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border: solid #999 1px;
}

body *::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border-radius: 20px;
  width: 80%;
  border: 2px solid transparent;
}
body *::-webkit-scrollbar-thumb:hover {
  background-color: #a9a9a9;
  width: 80%;
}

textarea::-webkit-scrollbar {
  width: 16px; 
}

.table-row {
  height: 5px !important;
}


@media only screen and (max-width: 1750px) {

  .reducTab, .reducPriseNote
  {
    width: 95%;
    
  }	
  
}
@media only screen and (max-width: 1450px) {

  .reducTab, .reducPriseNote
  {
    width: 85%;
    
  }	
  
}

@media only screen and (max-width: 1100px) {

  .reducTab, .reducPriseNote
  {
    width: 80%;
    
  }	

}

@media only screen and (max-width: 1000px) {

  .reducTab, .reducPriseNote
  {
    width: 75%;
    
  }	
  .reducPriseNote
  {
    margin-right: 0;
  }

}

/*Customisation of tables*/
table tr td {
  padding-left: 5px !important;
}

body.dark-theme .mat-header-row,
body.dark-theme .mat-header-cell {
  border: 1px solid $dark-theme-mat-header-cell-border-color !important;
  border-top: 0px solid $dark-theme-mat-header-cell-border-color !important;
  resize: horizontal;
  // overflow: hidden;
  height: 25px !important;
  padding-left: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: $dark-theme-mat-header-row-background-color !important;
}
body.dark-theme .mat-sort-header-arrow {
  color: $accent;
}
body.dark-theme .mat-row:nth-child(even){
  background-color: $dark-theme-mat-row-background-color-even !important;
}
      
body.dark-theme .mat-row:nth-child(odd){
  background-color: $dark-theme-mat-row-background-color-odd !important;
}

body.dark-theme .mat-cell{
  border: 1px solid $dark-theme-mat-cell-border-color !important;
}

body.light-theme .mat-header-row,
body.light-theme .mat-header-cell {
  border: 1px solid $light-theme-mat-header-cell-border-color !important;
  border-top: 0px solid $light-theme-mat-header-cell-border-color !important;
  resize: horizontal;
  // overflow: hidden;
  color: $primary;
  height: 25px !important;
  padding-left: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: $light-theme-mat-header-row-background-color !important;
}

body.light-theme .mat-row:nth-child(even){
  background-color: $light-theme-mat-row-background-color-even !important;
}
      
body.light-theme .mat-row:nth-child(odd){
  background-color: $light-theme-mat-row-background-color-odd !important;
}

body.light-theme .mat-cell{
  border: 1px solid $light-theme-mat-cell-background-color !important;
}

body.dark-theme .mat-row:hover {
  cursor: pointer !important;
  background-color : $dark-theme-mat-row-background-color !important;
}

body.light-theme .mat-row:hover {
  background-color : $light-theme-mat-row-background-color !important;
  cursor: pointer !important;
}

.body-class {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.element-left {
  padding-left: 0;
}

:host ::ng-deep.mat-paginator .mat-paginator-container{
  display: inline-block; 
  flex-wrap: nowrap !important; 
  white-space: nowrap !important;
}

.element-right {
  padding-right: 0;
}

.texte-info-bulle {
  border-radius: 50px !important; 
  background-color: #005EA8 !important; 
  color:white !important; 
  width: 34px; 
  height: 34px; 
  text-align: center !important; 
  font-size: 12px !important; 
  font-weight: bold !important; 
  padding-top: 8px !important; 
  padding-left: 5px !important;
}

.element-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.element-padding-top-10 {
  padding-top: 10px !important;
}

.data-class {
  border-radius: 4px;
}

.header-class {
  display: inline-flex; 
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.element-top-bottom-6 {
  padding-top: 6px !important; 
  padding-bottom: 6px !important;
}

button {
  transform: scale(0.85);
}

.table_overflow_x {
  width: 100%; overflow-x: auto;
}

.tableau-btn {
  height: 20px !important;
  width: 30px !important;
  border-radius: 20% !important;
  margin-right: 2px !important;
}

.tableau-mat-icon {
  margin-top: -50% !important;
}


@mixin mat-table-columns($columns) {
  .mat-column- {
      @each $colName, $props in $columns {
          $width: map-get($props, "width");

          &#{$colName} {
              flex: $width;
              min-width: $width;
              width: $width;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
          }
      }
  }
}

.tier-array {
  padding-left: 0;
}