@font-face {
    font-family: 'Sansa Pro Bold';
    src: url('/assets/fonts/SansaPro-Normal.woff2') format('woff2'),
        url('/assets/fonts/SansaPro-Normal.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sansa Pro';
    src: url('/assets/fonts/SansaPro-Normal.woff2') format('woff2'),
         url('/assets/fonts/SansaPro-Normal.woff') format('woff'),
         url('/assets/fonts/SansaPro-Normal.ttf') format('truetype'); /* Add TTF */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Roboto-Bold;
    src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-display: optional;
}
